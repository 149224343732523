import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/query';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { event_serviceAPI } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { GetEventFindApiArg } from 'src/app/redux/queries/event-service/types/reqTypes';
import { EventInfoFindResultResponse } from 'src/app/redux/queries/event-service/types/resTypes';
import { isPastDate } from 'src/shared/lib/date';
import { DateRangeInput } from 'src/shared/ui/_inputs/date_pickers/DateRangeInput';
import { SelectSingleWithSearch } from 'src/shared/ui/_inputs/selects/SelectSingleWithSearch';
import s from './FilterEvents.module.scss';

interface Props {
	filterEvents: LazyQueryTrigger<
		QueryDefinition<
			GetEventFindApiArg,
			BaseQueryFn,
			'Category' | 'Event' | 'EventCategory' | 'EventComment' | 'EventUser' | 'File' | 'Image' | 'UserBirthday',
			EventInfoFindResultResponse,
			'eventservice'
		>
	>;
	activeTabId: string;
}

export const FilterEvents: React.FC<Props> = props => {
	const {
		filterEvents, //
		activeTabId,
	} = props;

	//* API
	const { data: eventsData } = event_serviceAPI.useGetEventFindQuery({
		skipcount: 0,
		takecount: 1000,
		access: 'Opened',
		includeDeactivated: true,
	});

	// Названия и описания событий
	const eventNames =
		eventsData?.body
			?.filter(item => {
				if (activeTabId === 'events') return item.isActive && (!isPastDate(new Date(item.date)) || (item.endDate && !isPastDate(new Date(item.endDate))));
				if (activeTabId === 'past') return item.isActive && isPastDate(new Date(item.date)) && (item.endDate ? isPastDate(new Date(item.endDate)) : true);
				if (activeTabId === 'archive') return !item.isActive;
			})
			?.map(item => {
				const nameValue = { name: item.name, id: `name_${item.id}` };
				if (!item?.description) return [nameValue];

				const descriptionValue = { name: item.description, id: `description_${item.id}` };
				return [nameValue, descriptionValue];
			})
			.flat() || [];

	// * Filter
	const [name, setName] = useState({ name: '', id: '' });
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);

	useEffect(() => {
		setName({ name: '', id: '' });
		setStartDate(null);
		setEndDate(null);
	}, [activeTabId]);

	// * Filter apply
	useEffect(() => {
		const startTime = startDate ? DateTime.fromJSDate(startDate).startOf('day').toString() : '';
		const endTime = endDate ? DateTime.fromJSDate(endDate).startOf('day').toString() : '';

		filterEvents(
			{
				skipcount: 0,
				takecount: 1000,
				access: 'Opened',
				includeDeactivated: true,
				...(name.name && { nameIncludeSubstring: name.name.toLocaleLowerCase() }),
				...(startTime && { startTime }),
				...(endTime && { endTime }),
			},
			true,
		);
	}, [name, startDate, endDate]);

	// * Render
	return (
		<div className={s.wrapper}>
			<SelectSingleWithSearch
				placeholder="Поиск по названию и описанию"
				keyNames={{ name: 'name', value: 'id' }}
				selectedOption={name}
				setSelectedOption={value => setName(value)}
				options={eventNames}
				isNullable
			/>
			<DateRangeInput
				startDate={startDate}
				setStartDate={date => setStartDate(date)}
				endDate={endDate}
				setEndDate={date => setEndDate(date)}
				isClearable
			/>
		</div>
	);
};
