import cn from 'classnames';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { MOCK_CALENDAR, MOCK_USERS, MOCK_VACATIONS } from '../../../../mock';
import s from './Table.module.scss';
import { CellInfo } from '../../types';
import { VacationBar } from '../VacationBar';

let timeOut: any = null;

interface Props {
	users: typeof MOCK_USERS;
}

export const Table: FC<Props> = props => {
	const { users } = props;

	const [selectedYear, setSelectedYear] = useState(MOCK_CALENDAR[0]);

	const [startCellInfo, setStartCellInfo] = useState<CellInfo | null>(null);
	const [hoverCellInfo, setHoverCellInfo] = useState<CellInfo | null>(null);

	const [endCellInfo, setEndCellInfo] = useState<CellInfo | null>(null);

	const onCellClick = (cellInfo: CellInfo, replace?: boolean) => {
		if (replace) {
			setStartCellInfo(cellInfo);
		} else if (startCellInfo) {
			const startDateMS = DateTime.fromObject({ year: startCellInfo.year, month: startCellInfo.month, day: startCellInfo.day }).toMillis();
			const clickedCellMS = DateTime.fromObject({ year: cellInfo.year, month: cellInfo.month, day: cellInfo.day }).toMillis();

			if (cellInfo?.rowIndex !== startCellInfo.rowIndex) {
				setStartCellInfo(cellInfo);
			} else if (clickedCellMS < startDateMS) {
				setStartCellInfo(cellInfo);
			} else {
				console.log('Открыть с формой заполнения отпуска');
				setStartCellInfo(null);
				setEndCellInfo(null);
			}
		} else {
			setStartCellInfo(cellInfo);
		}
	};

	// * Render
	return (
		<div className={s.container}>
			<div className={s.calendar}>
				{selectedYear.months.map((month, monthIndex) => (
					<div
						key={month.name}
						className={s.column}
					>
						<div className={s.header}>
							<div className={cn(s.row, s.row__month)}>{month.name}</div>
							<div className={s.row}>
								{Array.from(Array(month.holidays.length).keys()).map(dayIndex => (
									<div
										key={dayIndex}
										className={cn(s.cell, s.cell__day)}
									>
										{dayIndex + 1}
									</div>
								))}
							</div>
						</div>

						{users.map((user, rowIndex) => (
							<div
								key={user.id}
								className={s.row}
							>
								<VacationBar
									user={user}
									monthIndex={monthIndex}
									selectedYear={selectedYear}
								/>

								{month.holidays.split('').map((holidayBinary, dayIndex) => {
									const isHoliday = !!+holidayBinary;
									const cellInfo: CellInfo = { day: dayIndex + 1, month: monthIndex + 1, year: selectedYear.year, userId: MOCK_USERS[rowIndex].id, rowIndex, leftPosition: 0 };

									const isSelected =
										startCellInfo?.day === cellInfo.day && startCellInfo?.month === cellInfo.month && startCellInfo?.year === cellInfo.year && startCellInfo?.userId === cellInfo.userId;

									let isHighlighted = false;
									let replace = false;
									if (startCellInfo && hoverCellInfo) {
										const sameRowAsStart = startCellInfo?.rowIndex === hoverCellInfo?.rowIndex && cellInfo.rowIndex === hoverCellInfo?.rowIndex;

										if (sameRowAsStart) {
											const startDateMS = DateTime.fromObject({ year: startCellInfo.year, month: startCellInfo.month, day: startCellInfo.day }).toMillis();
											const hoverDateMS = DateTime.fromObject({ year: hoverCellInfo.year, month: hoverCellInfo.month, day: hoverCellInfo.day }).toMillis();
											const cellDateMS = DateTime.fromObject({ year: cellInfo.year, month: cellInfo.month, day: cellInfo.day }).toMillis();

											const inBetweenStartAndHover = cellDateMS > startDateMS && cellDateMS < hoverDateMS;

											isHighlighted = inBetweenStartAndHover;

											const userVacations = MOCK_VACATIONS.find(vacation => vacation.user.id === user.id)?.vacations;

											const userVacationsMS = [];

											if (userVacations) {
												for (const vacation of userVacations) {
													userVacationsMS.push({
														startDateMS: DateTime.fromFormat(vacation.startDate, 'yyyy-MM-dd').toMillis(),
														endDateMS: DateTime.fromFormat(vacation.endDate, 'yyyy-MM-dd').toMillis(),
													});
												}
											}

											if (userVacationsMS.length) {
												const firstVacationAfterStart = userVacationsMS.find(vacation => vacation.startDateMS > startDateMS);
												if (firstVacationAfterStart) {
													if (hoverDateMS > firstVacationAfterStart.endDateMS) {
														isHighlighted = false;
														replace = true;
													}
												}
											}
										}
									}

									return (
										<div
											key={dayIndex}
											className={cn(
												s.cell, //
												s.cell__selectable,
												isSelected || isHighlighted ? s.cell__selectable_selected : isHoliday && s.cell__selectable_holiday,
											)}
											data-day={dayIndex}
											data-month={monthIndex}
											onClick={() => onCellClick(cellInfo, replace)}
											onMouseOver={() => {
												clearTimeout(timeOut);
												!isSelected && setHoverCellInfo(cellInfo);
											}}
											onMouseLeave={() => {
												timeOut = setTimeout(() => setHoverCellInfo(null), 200);
											}}
										/>
									);
								})}
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};
