import { useState } from 'react';
import { MOCK_USERS } from '../../mock';
import { SortBy } from '../../types';
import { Table } from '../Table/ui/Table/Table';
import { UsersTable } from '../UsersTable';
import s from './VacationTable.module.scss';
import { Scrollbar } from 'react-scrollbars-custom';

export const VacationTable: React.FC = () => {
	const users = MOCK_USERS;

	const [sortBy, setSortBy] = useState<{ ascending: boolean; by: SortBy } | null>(null);

	const sortedUsers = sortBy
		? users.sort((a, b) => {
				if (sortBy.ascending) {
					return a[sortBy.by] > b[sortBy.by] ? 1 : -1;
				} else {
					return a[sortBy.by] < b[sortBy.by] ? 1 : -1;
				}
			})
		: users;

	// * Render
	return (
		<Scrollbar
			style={{ width: '100%', height: '30rem' }}
			trackXProps={{
				renderer: props => {
					const { elementRef, ...restProps } = props;
					return (
						<span
							{...restProps}
							ref={elementRef}
							style={{
								...restProps.style,
								width: 'calc(100% - 43.7rem)',
								right: '.6rem',
								left: 'none',
								backgroundColor: 'var(--color-bg-hover-75)',
								height: '.5rem',
							}}
						/>
					);
				},
			}}
			trackYProps={{
				renderer: props => {
					const { elementRef, ...restProps } = props;
					return (
						<span
							{...restProps}
							ref={elementRef}
							style={{
								...restProps.style,
								height: 'calc(100% - 3.8rem)',
								bottom: '.6rem',
								top: 'none',
								backgroundColor: 'var(--color-bg-hover-75)',
								width: '.5rem',
							}}
						/>
					);
				},
			}}
			thumbXProps={{
				renderer: props => {
					const { elementRef, ...restProps } = props;
					return (
						<div
							{...restProps}
							ref={elementRef}
							style={{ ...restProps.style, backgroundColor: 'var(--color-secondary-1)' }}
						/>
					);
				},
			}}
			thumbYProps={{
				renderer: props => {
					const { elementRef, ...restProps } = props;
					return (
						<div
							{...restProps}
							ref={elementRef}
							style={{ ...restProps.style, backgroundColor: 'var(--color-secondary-1)' }}
						/>
					);
				},
			}}
			contentProps={{
				renderer: props => {
					const { elementRef, ...restProps } = props;
					return (
						<div
							{...restProps}
							style={{ ...restProps.style, display: 'inline-block' }} // ! inline-block нужен чтобы position: sticky таблицы с пользователями работал корректно!
							ref={elementRef}
							className="Content"
						/>
					);
				},
			}}
		>
			<div className={s.container}>
				<UsersTable
					users={sortedUsers}
					sortBy={sortBy}
					setSortBy={setSortBy}
				/>
				<Table users={sortedUsers} />
			</div>
		</Scrollbar>
	);
};
