import cn from 'classnames';
import React, { FC, useState } from 'react';
import { Crop, PercentCrop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg/action/delete.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg/action/edit.svg';
import { Button } from 'src/shared/ui/_buttons/Button';
import { getAcronym } from 'src/shared/lib/string';
import { Heading } from 'src/shared/ui/Heading';
import { CroppableAvatarModal } from '../CroppableAvatarModal/CroppableAvatarModal';
import s from './AvatarUpdate.module.scss';
import { ConfirmModal } from 'src/entities/_modals/ConfirmModal/ConfirmModal';

interface Props {
	userId: string;
	avatarId: string;
	parentAvatarId: string;

	avatar?: string;
	name: string;
	onCloseModal: () => void;
}

const aspect = 1;

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: 'px',
				width: 228,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	);
}

export const AvatarUpdate: FC<Props> = props => {
	const { userId, avatarId, parentAvatarId, avatar, name, onCloseModal } = props;

	// * API
	const [createAvatar, { isLoading: isCreateLoading }] = user_serviceAPI.usePostAvatarCreateMutation();
	const [deleteAvatar, { isLoading: isDeleteLoading }] = user_serviceAPI.useDeleteAvatarRemoveMutation();

	// * Modal
	const [showCropContent, setShowCropContent] = useState(false);
	const toggleShowCropContent = () => setShowCropContent(prevState => !prevState);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const toggleShowDeleteModal = () => setShowDeleteModal(prevState => !prevState);

	// * Add image
	const [crop, setCrop] = useState<Crop>();
	const [imgSrc, setImgSrc] = useState(avatar || '');
	const [fileName, setFileName] = useState('');
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

	const onSetCrop = (percent: PercentCrop) => setCrop(percent);

	const updateImage = (content: string, extension: string, url: string) => {
		setImgSrc(url);
		toggleShowCropContent();

		createAvatar({
			createAvatarRequest: {
				userId,
				name: fileName,
				content,
				extension,
				isCurrentAvatar: true,
			},
		})
			.unwrap()
			.then(() => onCloseModal())
			.catch(err => console.error(err));
	};

	const onCompleteCrop = (pixel: PixelCrop) => setCompletedCrop(pixel);

	const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			setCrop(undefined);

			const reader = new FileReader();

			reader.addEventListener('load', () => {
				setImgSrc(reader.result?.toString() || '');
				setShowCropContent(true);
			});
			reader.readAsDataURL(e.target.files[0]);

			setFileName(e.target.files[0].name);
		}
	};

	const onImageLoad = () => {
		if (aspect) {
			setCrop(centerAspectCrop(100, 100, aspect));
		}
	};

	const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
		const element = event.target as HTMLInputElement;
		element.value = '';
	};

	// * Delete avatar
	const onDeleteAvatar = () => {
		deleteAvatar({
			removeAvatarsRequest: {
				userId,
				avatarsIds: [avatarId, parentAvatarId],
			},
		})
			.unwrap()
			.then(() => onCloseModal())
			.catch(err => console.error(err));
	};

	// * Render
	return (
		<>
			{showCropContent ? (
				<CroppableAvatarModal
					onSetCrop={onSetCrop}
					imageSrc={imgSrc}
					crop={crop}
					onImageLoad={onImageLoad}
					onComplete={onCompleteCrop}
					imageSize={{ height: 228, width: 228 }}
					completedCrop={completedCrop}
					updateImage={updateImage}
					closeModal={toggleShowCropContent}
					deleteImage={() => setImgSrc(avatar || '')}
				/>
			) : (
				<>
					{showDeleteModal && (
						<ConfirmModal
							isOpen={showDeleteModal}
							onClose={toggleShowDeleteModal}
							title="Удаление фотографии"
							description="Вы действительно хотите удалить фотографию?"
							primaryOnClick={onDeleteAvatar}
							isLoading={isDeleteLoading}
						/>
					)}

					<div className={s.container}>
						<Heading
							level={2}
							marginBottom="l"
							className={s.header}
						>
							Редактирование фотографии
						</Heading>

						<div className={cn(s.Avatar_container, s.Avatar_size)}>
							{imgSrc ? (
								<>
									<img
										className={s.Avatar_size}
										src={imgSrc}
										alt="avatar-img"
									/>
								</>
							) : (
								<span className={s.Avatar_name}>{getAcronym(name)}</span>
							)}
						</div>

						<div className={s.buttons}>
							<Button
								type="button"
								variant="tertiary"
								onClick={avatar ? toggleShowDeleteModal : onCloseModal}
								fixedWidth
								isLoading={isCreateLoading}
								LeftIcon={avatar && <DeleteSVG />}
							>
								{avatar ? 'Удалить' : 'Отменить'}
							</Button>

							<div className={s.btn_wrapper}>
								<Button
									type="button"
									variant="primary"
									fixedWidth
									isLoading={isCreateLoading}
									LeftIcon={<EditSVG />}
								>
									Сменить
								</Button>

								<label
									htmlFor="avatar"
									className={s.label}
								/>
								<input
									type="file"
									style={{ display: 'none' }}
									id="avatar"
									onChange={onSelectFile}
									onClick={onInputClick}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};
