import { LIST_OF_MONTHS_NAMES } from 'src/shared/consts/consts';

export const MOCK_USERS = [
	{
		id: '1',
		name: 'User 1',
		category: 'category 1',
		group: 'test',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '2',
		name: 'User 3',
		category: 'category 1',
		group: 'test 3',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '3',
		name: 'User 2',
		category: 'category 1',
		group: 'test 2',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '4',
		name: 'User 4',
		category: 'category 1',
		group: 'test',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '5',
		name: 'User 5',
		category: 'category 2',
		group: 'test 2',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '6',
		name: 'User 6',
		category: 'category 2',
		group: 'test 2',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '7',
		name: 'User 7',
		category: 'category 11111111111111',
		group: 'test',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '8',
		name: 'User 8',
		category: 'category 1',
		group: 'test',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '9',
		name: 'User 9',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '10',
		name: 'User 10',
		category: 'category 1',
		group: 'test',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '11',
		name: 'User 11',
		category: 'category 1',
		group: 'test',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '12',
		name: 'User 13',
		category: 'category 1',
		group: 'test 3',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '13',
		name: 'User 12',
		category: 'category 1',
		group: 'test 2',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '14',
		name: 'User 14',
		category: 'category 1',
		group: 'test',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '15',
		name: 'User 15',
		category: 'category 2',
		group: 'test 2',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '16',
		name: 'User 16',
		category: 'category 2',
		group: 'test 2',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '17',
		name: 'User 17',
		category: 'category 11111111111111',
		group: 'test',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '18',
		name: 'User 18',
		category: 'category 1',
		group: 'test',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '19',
		name: 'User 19',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '20',
		name: 'User 20',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '21',
		name: 'User 21',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '22',
		name: 'User 22',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '23',
		name: 'User 23',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '24',
		name: 'User 24',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '25',
		name: 'User 25',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '26',
		name: 'User 26',
		category: 'category 1',
		group: 'test',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '27',
		name: 'User 27',
		category: 'category 1',
		group: 'test 3',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '28',
		name: 'User 28',
		category: 'category 1',
		group: 'test 2',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '29',
		name: 'User 29',
		category: 'category 1',
		group: 'test',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '30',
		name: 'User 30',
		category: 'category 2',
		group: 'test 2',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '31',
		name: 'User 31',
		category: 'category 2',
		group: 'test 2',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '32',
		name: 'User 32',
		category: 'category 11111111111111',
		group: 'test',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '33',
		name: 'User 33',
		category: 'category 1',
		group: 'test',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '34',
		name: 'User 34',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '35',
		name: 'User 35',
		category: 'category 1',
		group: 'test',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '36',
		name: 'User 36',
		category: 'category 1',
		group: 'test',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '37',
		name: 'User 37',
		category: 'category 1',
		group: 'test 3',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '38',
		name: 'User 38',
		category: 'category 1',
		group: 'test 2',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '39',
		name: 'User 39',
		category: 'category 1',
		group: 'test',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '40',
		name: 'User 40',
		category: 'category 2',
		group: 'test 2',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '41',
		name: 'User 41',
		category: 'category 2',
		group: 'test 2',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '42',
		name: 'User 42',
		category: 'category 11111111111111',
		group: 'test',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '43',
		name: 'User 43',
		category: 'category 1',
		group: 'test',
		team: 'team asdfefef',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '44',
		name: 'User 44',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '45',
		name: 'User 45',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '46',
		name: 'User 46',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '47',
		name: 'User 47',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '48',
		name: 'User 48',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '49',
		name: 'User 49',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
	{
		id: '50',
		name: 'User 50',
		category: 'category 2',
		group: 'test 2',
		team: 'team gggggggg',
		days: {
			total: 30,
			left: 0,
			used: 0,
		},
	},
];

export const MOCK_VACATIONS = [
	{
		user: {
			id: '1',
		},
		vacations: [
			{
				id: '1',
				title: 'Vacation 1',
				startDate: '2024-01-16',
				endDate: '2024-01-20',
				type: 'asfdefefefefefefefeff',
			},
			{
				id: '2',
				title: 'Vacation 2',
				startDate: '2024-01-21',
				endDate: '2024-01-27',
				type: 'planedVacation',
			},
			{
				id: '3',
				title: 'Vacation 3',
				startDate: '2024-01-28',
				endDate: '2024-01-30',
				type: 'vacation',
			},
		],
	},
	{
		user: {
			id: '2',
		},
		vacations: [
			{
				id: '2',
				title: 'Vacation 2',
				startDate: '2024-01-01',
				endDate: '2024-02-02',
				type: 'sickLeave',
			},
		],
	},
	{
		user: {
			id: '3',
		},
		vacations: [
			{
				id: '3',
				title: 'Vacation 3',
				startDate: '2024-02-20',
				endDate: '2024-04-10',
				type: 'vacation',
			},
		],
	},
	{
		user: {
			id: '3',
		},
		vacations: [
			{
				id: '10',
				title: 'Vacation 3',
				startDate: '2024-02-20',
				endDate: '2024-04-10',
				type: 'planedVacation',
			},
		],
	},
	{
		user: {
			id: '3',
		},
		vacations: [
			{
				id: '10',
				title: 'Vacation 3',
				startDate: '2024-02-20',
				endDate: '2024-04-10',
				type: 'training',
			},
		],
	},
	{
		user: {
			id: '3',
		},
		vacations: [
			{
				id: '10',
				title: 'Vacation 3',
				startDate: '2024-02-20',
				endDate: '2024-04-10',
				type: 'idle',
			},
		],
	},
];

export const MOCK_CALENDAR = [
	{
		year: 2024,
		months: [
			{ name: LIST_OF_MONTHS_NAMES[0], holidays: '1111111100001100000110000011000' },
			{ name: LIST_OF_MONTHS_NAMES[1], holidays: '00110000011000001100001110000' },
			{ name: LIST_OF_MONTHS_NAMES[2], holidays: '0110000111000001100000110000011' },
			{ name: LIST_OF_MONTHS_NAMES[3], holidays: '000001100000110000011000000111' },
			{ name: LIST_OF_MONTHS_NAMES[4], holidays: '1001100011110000011000001100000' },
			{ name: LIST_OF_MONTHS_NAMES[5], holidays: '110000011001001100000110000011' },
			{ name: LIST_OF_MONTHS_NAMES[6], holidays: '0000011000001100000110000011000' },
			{ name: LIST_OF_MONTHS_NAMES[7], holidays: '0011000001100000110000011000001' },
			{ name: LIST_OF_MONTHS_NAMES[8], holidays: '100000110000011000001100000110' },
			{ name: LIST_OF_MONTHS_NAMES[9], holidays: '0000110000011000001100000110000' },
			{ name: LIST_OF_MONTHS_NAMES[10], holidays: '001100001100000110000011000001' },
			{ name: LIST_OF_MONTHS_NAMES[11], holidays: '001100001100000110000011000001' },
		],
	},
];
