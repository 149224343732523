import { useGetUserBirthdayFindQuery } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { getStartAndEndDates } from 'src/pages/event_calendar/ui/EventCalendarPage/lib';
import { backendISOUTCToLocalLuxon, dateToBackendISO } from 'src/shared/lib/date';
import { createFullName } from 'src/shared/lib/string';
import { ConditionalRendering } from 'src/shared/providers';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { Heading } from 'src/shared/ui/Heading';
import { BirthdayUser } from '../../../consts/consts';
import { SingleBirthdayUser } from '../SingleBirthdayUser/SingleBirthdayUser';
import s from './BirthdayModal.module.scss';

interface GroupedByDay {
	[key: number]: BirthdayUser[];
}

interface Props {
	month: string;
	isOpen: boolean;
	onClose: () => void;
}

export const BirthdayModal: React.FC<Props> = props => {
	const {
		month, //
		isOpen,
		onClose,
	} = props;

	const { startDate, endDate } = getStartAndEndDates(month);

	// * API
	const { data: birthdayData, isLoading } = useGetUserBirthdayFindQuery({
		startTime: dateToBackendISO(startDate) as string,
		endTime: dateToBackendISO(endDate) as string,
		skipcount: 0,
		takecount: 1000,
	});

	const users =
		birthdayData?.body?.map(item => {
			const { name, lastName, middleName } = item;
			return {
				...item,
				fullName: createFullName({ firstName: name, lastName, middleName: middleName || undefined }),
				day: backendISOUTCToLocalLuxon(item.dateOfBirth).day,
			};
		}) || [];

	// Группировка сотрудников по дню рождения
	const grouped =
		users?.reduce<GroupedByDay>((acc, item) => {
			if (!acc[item.day]) {
				acc[item.day] = [];
			}
			acc[item.day].push(item);
			return acc;
		}, {}) || [];

	const groupedByDay = Object.entries(grouped).map(([day, users]) => ({
		day,
		users,
	}));

	// * Render
	return (
		<ModalNewProto
			width="s"
			isOpen={isOpen}
			onClose={onClose}
		>
			<Heading
				level={3}
				className={s.title}
			>
				Дни рождения
				<span className={s.title_month}> {month}</span>
			</Heading>

			<div className={s.wrapper}>
				<ConditionalRendering
					initialLoading={isLoading}
					isSuccessful={groupedByDay.length > 0}
					FailedResult={<p className={s.message}>В этом месяце дней рождений нет</p>}
					LoadedResult={groupedByDay?.map(({ day, users }) => (
						<div key={day}>
							<p className={s.day}>{day}</p>
							{users.map((user: BirthdayUser) => (
								<SingleBirthdayUser
									key={user.userId}
									user={user}
								/>
							))}
						</div>
					))}
				/>
			</div>
		</ModalNewProto>
	);
};
