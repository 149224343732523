import { DateTime } from 'luxon';
import { UserBirthdayInfo } from 'src/app/redux/queries/event-service/types/types';
import { SingleTab } from 'src/shared/ui/_tabs/lineTabs/LineTabs';

export const TABS: SingleTab[] = [
	{
		id: 'events',
		name: 'События',
	},
	{
		id: 'past',
		name: 'Прошедшие',
	},
	{
		id: 'archive',
		name: 'Архив',
	},
];

export const MIN_DATE = DateTime.fromObject({ year: 2023, month: 9, day: 23 }).toJSDate();

export interface BirthdayUser extends UserBirthdayInfo {
	day: number;
	fullName: string;
}
