import { useState } from 'react';
import { useGetCategoryFindQuery, useLazyGetEventFindQuery } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { isPastDate } from 'src/shared/lib/date';
import { ConditionalRendering } from 'src/shared/providers';
import { LineTabs } from 'src/shared/ui/_tabs/lineTabs';
import { EmptyData } from 'src/shared/ui/EmptyData/EmptyData';
import { Categories } from 'src/widgets/Categories';
import { TABS } from '../../consts/consts';
import { useGetCategoryActions } from '../../hooks/useGetCategoryActions';
import { EventsContent } from '../EventsContent/EventsContent';
import { FilterEvents } from '../FilterEvents/FilterEvents';
import s from './EventList.module.scss';

export const EventList = () => {
	const [activeTabId, setActiveTabId] = useState<string>(TABS[0].id);

	// * API
	const [filterEvents, { data: eventsData, isLoading: filterLoading }] = useLazyGetEventFindQuery();

	const { data: categoriesData } = useGetCategoryFindQuery({
		skipcount: 0,
		takecount: 100,
	});

	const categories = categoriesData?.body || [];

	const events =
		eventsData?.body?.map(event => ({
			...event,
			date: new Date(event.date),
			endDate: event.endDate ? new Date(event.endDate) : null,
		})) || [];

	const activeEvents = events.filter(event => event.isActive && (!isPastDate(event.date) || (event.endDate && !isPastDate(event.endDate))));
	const pastEvents = events.filter(event => event.isActive && isPastDate(event.date) && (event.endDate ? isPastDate(event.endDate) : true));
	const archiveEvents = events.filter(event => !event.isActive);

	// - Category actions
	const { addCategory, editCategory, removeCategory, isLoading } = useGetCategoryActions();

	// * Render
	return (
		<div className={s.wrapper}>
			<Categories
				categories={categories}
				getCategoryId={removeCategory}
				getCategoryInfo={editCategory}
				getNewCategoryInfo={addCategory}
				isLoading={isLoading}
			/>
			<div>
				<FilterEvents
					filterEvents={filterEvents}
					activeTabId={activeTabId}
				/>
				<LineTabs
					tabs={TABS}
					className={s.line_tabs}
					activeTabId={activeTabId}
					setActiveTabId={setActiveTabId}
				/>

				{activeTabId === 'events' && (
					<ConditionalRendering
						initialLoading={filterLoading}
						isSuccessful={activeEvents.length > 0}
						LoadedResult={
							<EventsContent
								events={activeEvents}
								activeTabId={activeTabId}
							/>
						}
						FailedResult={<EmptyData />}
					/>
				)}

				{activeTabId === 'past' && (
					<ConditionalRendering
						initialLoading={filterLoading}
						isSuccessful={pastEvents.length > 0}
						LoadedResult={
							<EventsContent
								events={pastEvents}
								activeTabId={activeTabId}
							/>
						}
						FailedResult={<EmptyData />}
					/>
				)}

				{activeTabId === 'archive' && (
					<ConditionalRendering
						initialLoading={filterLoading}
						isSuccessful={archiveEvents.length > 0}
						LoadedResult={
							<EventsContent
								events={archiveEvents}
								activeTabId={activeTabId}
							/>
						}
						FailedResult={<EmptyData />}
					/>
				)}
			</div>
		</div>
	);
};
