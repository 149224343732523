import cn from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePatchEventEditMutation } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { CategoryInfo } from 'src/app/redux/queries/event-service/types/types';
import { routerPath } from 'src/app/router/paths';
import { ConfirmModal } from 'src/entities/_modals/ConfirmModal/ConfirmModal';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ReactComponent as ArchiveSVG } from 'src/shared/assets/svg_icons/file/archive.svg';
import { ReactComponent as UnArchiveSVG } from 'src/shared/assets/svg_icons/file/unarchive.svg';
import { dateToFullRuDateString, isDateInRange, isPastDate } from 'src/shared/lib/date';
import { Button } from 'src/shared/ui/_buttons/Button';
import { ChipCategory } from 'src/shared/ui/_chips/ChipCategory/ChipCategory';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { Heading } from 'src/shared/ui/Heading';
import { Event } from '../../../../consts/consts';
import { compareEvents } from '../../../../lib';
import { getCategoryInfo } from '../../../../lib/getCategoryInfo/getCategoryInfo';
import { BirthdayModal } from '../BirthdayModal/BirthdayModal/BirthdayModal';
import s from './EventsContent.module.scss';

interface GroupedEvents {
	[key: string]: Event[];
}

interface Props {
	events: Event[];
	activeTabId: string;
}

const TODAY_DATE = new Date();

export const EventsContent: React.FC<Props> = props => {
	const {
		events, //
		activeTabId,
	} = props;

	const navigate = useNavigate();
	const isArchive = activeTabId === 'archive';

	const [eventId, setEventId] = useState('');
	const [selectedMonth, setSelectedMonth] = useState('');

	const [showArchiveModal, setShowArchiveModal] = useState(false);
	const toggleArchiveModal = () => setShowArchiveModal(prevState => !prevState);

	const [showBirthdayModal, setShowBirthdayModal] = useState(false);
	const toggleBirthdayModal = () => setShowBirthdayModal(prevState => !prevState);

	// * API
	const [editEvent, { isLoading }] = usePatchEventEditMutation();

	const onArchive = () => {
		editEvent({
			eventId: eventId,
			body: [
				{
					op: 'replace',
					path: '/IsActive',
					value: isArchive,
				},
			],
		})
			.then(() => toggleArchiveModal())
			.catch(e => console.log(e));
	};

	const handleClickPage = (id: string) => {
		navigate(`${routerPath.calendar.page}${routerPath.calendar.event}/${id}`);
	};

	const handleEdit = (id: string) => {
		navigate(`${routerPath.calendar.page}${routerPath.calendar.editEvent}/${id}`);
	};

	// * Menu
	const dropDownItems = [
		{
			isVisible: activeTabId === 'events',
			icon: <EditSVG />,
			title: 'Редактировать',
			action: (id?: string) => {
				if (!id) return;
				handleEdit(id);
			},
		},
		{
			isVisible: !isArchive,
			icon: <ArchiveSVG />,
			title: 'Архивировать',
			action: (id?: string) => {
				if (!id) return;
				setEventId(id);
				toggleArchiveModal();
			},
		},
		{
			isVisible: isArchive,
			icon: <UnArchiveSVG />,
			title: 'Восстановить',
			action: (id?: string) => {
				if (!id) return;
				handleEdit(id);
			},
		},
	].filter(item => item.isVisible);

	// Группировка событий по месяцам
	const sortedEvents = [...events].sort(compareEvents);
	const groupedByMonth = sortedEvents.reduce<GroupedEvents>((acc, event) => {
		const monthLower = event.date.toLocaleString('ru-RU', { month: 'long' });
		const month = monthLower[0].toUpperCase() + monthLower.slice(1);

		if (!acc[month]) acc[month] = [];
		acc[month].push(event);
		return acc;
	}, {});

	const groupedEvents = Object.entries(groupedByMonth).map(([month, events]) => ({
		month,
		events,
	}));

	const renderCategories = (categories: CategoryInfo[]) => {
		const { name, color } = getCategoryInfo(categories);
		return (
			<ChipCategory
				name={name}
				color={color}
			/>
		);
	};

	const renderEventStatus = (start: Date, end: Date | null | undefined) => {
		if (end && isDateInRange(TODAY_DATE, [start, end])) return <span className={s.info_status_active}>Уже идет</span>;
		if (isPastDate(start) && (end ? isPastDate(end) : true)) return <span className={s.info_status_past}>Прошло</span>;
	};

	// * Render
	return (
		<div>
			{showArchiveModal && (
				<ConfirmModal
					isOpen={showArchiveModal}
					onClose={toggleArchiveModal}
					title={'Архивировать'}
					description={`Вы действительно хотите архивировать выбранное событие?`}
					primaryText={'Архивировать'}
					primaryOnClick={onArchive}
					isLoading={isLoading}
				/>
			)}

			{showBirthdayModal && (
				<BirthdayModal
					month={selectedMonth}
					isOpen={showBirthdayModal}
					onClose={toggleBirthdayModal}
				/>
			)}

			{groupedEvents.map(({ month, events }) => (
				<div
					key={month}
					className={s.month_section}
				>
					<div className={cn(s.flex_wrapper, s.month_section_title)}>
						<Heading level={3}>{month}</Heading>
						<Button
							variant="tertiary"
							onClick={() => {
								toggleBirthdayModal();
								setSelectedMonth(month);
							}}
						>
							Дни рождения
						</Button>
					</div>

					{events.map(event => (
						<div
							key={event.id}
							className={s.card}
							onClick={() => handleClickPage(event.id)}
						>
							<div className={s.flex_wrapper}>
								<div>
									<div className={s.info}>
										<span className={s.info_date}>{dateToFullRuDateString(event.date)}</span>
										{renderEventStatus(event.date, event.endDate)}
									</div>
									<p className={s.title}>{event.name}</p>
								</div>

								<DropDownMenu
									items={dropDownItems}
									value={event.id}
								/>
							</div>

							<div>
								<p className={s.section_name}>Описание</p>
								<p>{event.description}</p>
							</div>

							<div>
								<p className={s.section_name}>Адрес</p>
								<p>-</p>
							</div>

							{event.eventsCategories && renderCategories(event.eventsCategories)}
						</div>
					))}
				</div>
			))}
		</div>
	);
};
