import { FC } from 'react';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair/ButtonPair';
import { Heading } from 'src/shared/ui/Heading';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import s from './ConfirmModal.module.scss';

interface Props {
	isOpen: boolean;
	onClose: () => void;

	title: string;
	description?: string;

	primaryText?: string;
	primaryOnClick: () => void;

	isLoading: boolean;
	secondaryText?: string;
}

export const ConfirmModal: FC<Props> = props => {
	const {
		isOpen,
		onClose,

		title,
		description,

		primaryText = 'Удалить',
		primaryOnClick,

		isLoading,
		secondaryText = 'Отменить',
	} = props;

	// * Render
	return (
		<ModalNewProto
			isOpen={isOpen}
			onClose={onClose}
			className={s.modal}
		>
			<div className={s.container}>
				<Heading
					level={2}
					marginBottom="l"
					className={s.header}
				>
					{title}
				</Heading>

				<div className={s.description}>{description}</div>

				<ButtonPair
					primaryText={primaryText}
					primaryIsLoading={isLoading}
					primaryOnClick={primaryOnClick}
					secondaryText={secondaryText}
					secondaryOnClick={onClose}
					secondaryIsLoading={isLoading}
				/>
			</div>
		</ModalNewProto>
	);
};
