import { FC, useState } from 'react';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ReactComponent as MailConfirmSVG } from 'src/shared/assets/svg_icons/action/mail_confirm.svg';
import { ReactComponent as StarSVG } from 'src/shared/assets/svg_icons/action/star_stroke.svg';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { ConfirmModal } from '../../../../../../../../entities/_modals/ConfirmModal/ConfirmModal';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { ContactForm } from '../ContactForm/ContactForm';
import { CommunicationType } from 'src/app/redux/queries/user-service/types/types';
import { BaseEmailChangeModal } from '../BaseEmailChangeModal/BaseEmailChangeModal';
import { ConfirmEmailModal } from '../ConfirmEmailModal/ConfirmEmailModal';

interface Props {
	contactId: string;
	type: CommunicationType;
	value: string;
	isConfirmed?: boolean;
}

export const ContactDropDown: FC<Props> = props => {
	const {
		contactId, //
		type,
		value,
		isConfirmed,
	} = props;

	// * API
	const [removeCommunication, { isLoading }] = user_serviceAPI.useDeleteCommunicationRemoveMutation();

	// * Modal
	const [removeContactModal, setRemoveContactModal] = useState(false);
	const toggleRemoveContactModal = () => setRemoveContactModal(prevState => !prevState);

	const [editContactModal, setEditContactModal] = useState(false);
	const toggleEditContactModal = () => setEditContactModal(prevState => !prevState);

	const [confirmEmailModal, setConfirmEmailModal] = useState(false);
	const toggleConfirmEmailModal = () => setConfirmEmailModal(prevState => !prevState);

	const [changeBaseEmail, setChangeBaseEmail] = useState(false);
	const toggleChangeBaseEmail = () => setChangeBaseEmail(prevState => !prevState);

	// * Actions
	const deleteContact = () => {
		removeCommunication({
			communicationId: contactId,
		})
			.unwrap()
			.catch(err => console.log(err));
	};

	// * Menu items
	const dropDownItems = [
		{
			icon: <StarSVG />,
			title: 'Сделать основной',
			action: () => toggleChangeBaseEmail(),
			isVisible: isConfirmed && type === 'Email',
		},
		{
			icon: <EditSVG />,
			title: 'Редактировать',
			action: () => toggleEditContactModal(),
			isVisible: !isConfirmed || type !== 'Email',
		},
		{
			icon: <DeleteSVG />,
			title: 'Удалить',
			action: () => toggleRemoveContactModal(),
			isVisible: true,
		},
		{
			icon: <MailConfirmSVG />,
			title: 'Подтвердить e-mail',
			action: () => toggleConfirmEmailModal(),
			isVisible: !isConfirmed && type === 'Email',
		},
	].filter(item => item.isVisible);

	// * Render
	return (
		<>
			{confirmEmailModal && (
				<ConfirmEmailModal
					id={contactId}
					email={value}
					isOpen={confirmEmailModal}
					onClose={toggleConfirmEmailModal}
				/>
			)}

			{changeBaseEmail && (
				<BaseEmailChangeModal
					id={contactId}
					email={value}
					isOpen={changeBaseEmail}
					onClose={toggleChangeBaseEmail}
				/>
			)}

			{removeContactModal && (
				<ConfirmModal
					isOpen={removeContactModal}
					onClose={toggleRemoveContactModal}
					title="Удаление контакта"
					description={`Вы действительно хотите удалить контакт\n${value}?`}
					primaryOnClick={deleteContact}
					isLoading={isLoading}
				/>
			)}

			{editContactModal && (
				<ModalNewProto
					isOpen={editContactModal}
					onClose={toggleEditContactModal}
				>
					<ContactForm
						contactId={contactId}
						type={type}
						value={value.startsWith('@') ? value.substring(1) : value.substring(0)}
						onClose={toggleEditContactModal}
					/>
				</ModalNewProto>
			)}

			<DropDownMenu
				value={contactId}
				items={dropDownItems}
			/>
		</>
	);
};
