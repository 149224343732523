import { DateTime } from 'luxon';
import { FC } from 'react';
import { WorkTimesInfoContextProvider } from 'src/app/contexts/useWorkTimesInfoContext';
import { useGetLeaveTimeFindQuery, useGetWorkTimeFindQuery, useGetWorkTimeMonthLimitFindQuery } from 'src/app/redux/queries/time-service/time_serviceAPI';
import { useAppSelector } from 'src/app/redux/utils';
import { TimeChart } from 'src/features/TimeChart';
import { Log } from 'src/pages/TimeTrackingPage/ui/Log/Log';
import { MAX_APP_DATE, MIN_APP_DATE } from 'src/shared/consts/consts';
import { getCurrentMonthNumber, getCurrentYearNumber, getMonthNumbers } from 'src/shared/lib/date';

interface Props {
	selectedDate: Date;
	setSelectedDate: (date: Date) => void;
}

export const InfoCards: FC<Props> = props => {
	const { selectedDate, setSelectedDate } = props;

	const userId = useAppSelector(state => state.user_service.user.userInfo?.user.id);

	const { currentMonth: month } = getMonthNumbers(selectedDate);

	const year = getCurrentYearNumber(selectedDate);

	const startTime = DateTime.fromJSDate(selectedDate).startOf('month').toISO();
	const endTime = DateTime.fromJSDate(selectedDate).endOf('month').toISO();

	// * API
	const { data: workTimesRes } = useGetWorkTimeFindQuery(
		{
			userid: userId,
			year,
			month,
			takecount: 1000, // ! HARDCODE
			skipcount: 0, // ! HARDCODE
		},
		{ skip: !userId },
	);

	const { data: leaveTimesRes } = useGetLeaveTimeFindQuery(
		{
			userid: userId,
			starttime: startTime,
			endtime: endTime,
			includedeactivated: true, // Получить удаленные записи отсутствий. isActive: false.
			takecount: 1000, // ! HARDCODE
			skipcount: 0, // ! HARDCODE
		},
		{ skip: !userId },
	);

	// - holidays for 3 months: current, prev and next
	const { data: limitsRequestCurrent } = useGetWorkTimeMonthLimitFindQuery({
		year: getCurrentYearNumber(selectedDate),
		month: getCurrentMonthNumber(selectedDate),
		takecount: 1000, // ! HARDCODE
		skipcount: 0, // ! HARDCODE
	});

	const { data: limitsRequestPrev } = useGetWorkTimeMonthLimitFindQuery({
		year: DateTime.fromJSDate(selectedDate).startOf('month').minus({ month: 1 }).year,
		month: DateTime.fromJSDate(selectedDate).startOf('month').minus({ month: 1 }).month,
		takecount: 1000, // ! HARDCODE
		skipcount: 0, // ! HARDCODE
	});

	const { data: limitsRequestNext } = useGetWorkTimeMonthLimitFindQuery({
		year: DateTime.fromJSDate(selectedDate).startOf('month').plus({ month: 1 }).year,
		month: DateTime.fromJSDate(selectedDate).startOf('month').plus({ month: 1 }).month,
		takecount: 1000, // ! HARDCODE
		skipcount: 0, // ! HARDCODE
	});

	// * Data
	const workTimeInfo = workTimesRes?.body ? workTimesRes.body.map(item => item.workTime) : [];
	const leaveTimesInfo = leaveTimesRes?.body ? leaveTimesRes.body : [];

	// - holidays
	const curMonthLimits = limitsRequestCurrent?.body || [];
	const prevMonthLimits = limitsRequestPrev?.body || [];
	const nextvMonthLimits = limitsRequestNext?.body || [];

	const monthsLimits = [...curMonthLimits, ...prevMonthLimits, ...nextvMonthLimits];

	// * Render
	return (
		<WorkTimesInfoContextProvider
			workTimeInfo={workTimeInfo}
			workTimeMonthLimitInfo={monthsLimits}
			leaveTimesInfo={leaveTimesInfo}
		>
			<TimeChart
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
				minDate={MIN_APP_DATE}
				maxDate={MAX_APP_DATE}
			/>

			<Log
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
				minDate={MIN_APP_DATE}
				maxDate={MAX_APP_DATE}
			/>
		</WorkTimesInfoContextProvider>
	);
};
