export const ERRORS = new Map([
	//* - Time Service
	['Отсутствие не должно пересекаться с другими', 'Период отсутствия не может пересекаться с другими отсутствиями.'],
	['Время начала должно быть не больше времени окончания отсутствия', 'Время начала отсутствия не может быть позже времени окончания отсутствия.'],
	[
		'Нельзя отредактировать внесенные в проект часы',
		'Количество рабочих часов не может быть отредактировано после внесения изменений руководителем  проекта или директором департамента.',
	],
	['В заданном диапазоне отсутствуют рабочие часы', 'В заданном диапазоне отсутствуют рабочие часы.'],

	//* - User Service
	['Communication value already exists. Check email address.', 'Такой адрес электронной почты уже существует.'],
	["Can't set unconfirmed email as base.", 'Нельзя выбрать неподтвержденный адрес электронной почты как основной.'],
	['Only a verified email can be set as the base email.', 'Только подтвержденный адрес электронной почты можно установить как основной.'],
	['Login must contain only Latin letters and digits or only Latin letters', 'Логин должен содержать только латинские буквы и цифры или только латинские буквы.'],
	['Login already in use', 'Этот логин уже используется.'],
	['Login is too long', 'Слишком длинный логин.'],
	['Login is too short', 'Слишком короткий логин.'],
	['Wrong password', 'Введён неверный пароль.'],
	['Password is too short', 'Слишком короткий пароль.'],
	['Password is too long', 'Слишком длинный пароль.'],
	['The password must contain at least one special character', 'Пароль должен содержать хотя бы один символ.'],
	['Password must not contain space', 'Пароль не должен содержать пробелов.'],
	['The first letter of the name must not be a special character.', 'Первая буква имени не может быть специальным символом.'],
	['The first letter of the last name must not be a special character.', 'Первая буква фамилии не может быть специальным символом.'],
	['The first letter of the middle name must not be a special character.', 'Первая буква отчества не может быть специальным символом.'],
	['Width must be greater than height', 'В фото профиля можно добавить только горизонтальное изображение.'],

	//* - Department Service
	['The department name already exist.', 'Такое название для департамента уже используется.'],
	['The department name already exists.', 'Такое название для департамента уже используется.'],
	['The department short name already exist.', 'Такое сокращённое название для департамента уже используется.'],
	['The department short name already exists.', 'Такое сокращённое название для департамента уже используется.'],
	['List of users must contain at least one user', 'Необходимо выбрать хотя бы одного сотрудника.'],
	["This department id doesn't exist.", 'Необходимо выбрать хотя бы одного сотрудника.'],
	[
		'There are some active users in this department or in its child departments, so this department cannot be archived.',
		'Невозможно архивировать департамент, к которому прикреплены активные сотрудники.',
	],
	['Only one user can be the department director', 'Только одному пользователю может быть назначена роль директора департамента.'],

	//* - Project Service
	['Department id must not be empty', 'При создании проекта необходимо прикрепить его к департаменту.'],
	['Проект с таким именем уже существует', 'Проект с таким названием уже существует.'],
	['Проект с таким сокращенным именем уже существует', 'Проект с таким сокращённым названием уже существует.'],
	['Если проект закрыт, у него должна быть дата закрытия', 'Необходимо ввести дату окончания проекта, если проект завершён.'],

	//* - Office Service
	["Only admin can manage other user's bookings.", 'Только пользователь с правами администратора может бронировать места другим пользователям.'],
	['Only admin can ban booking', 'Только пользователь с правами администратора может ставить запрет на бронирование'],
	["This workspace can't be booked", 'Данное рабочее место не может быть забронировано.'],
	['Workspace is already booked for specified period', 'Рабочее место уже забронировано на определённый период.'],
	['Minimum period for booking of workspace is one day', 'Минимальный период бронирования рабочего места - один день.'],
	['Not enough rights to remove booking of another user', 'Недостаточно прав для отмены бронирования другого пользователя.'],
	['Workspace can only be booked for whole days', 'Рабочее место может быть забронировано только на весь день.'],
	['Some of the workspaces in this room are already booked for specified period', 'Часть рабочих мест в данном помещении уже забронировано.'],
	['This room is already booked for specified period', 'Помещение уже забронировано.'],
	["Room with partial booking room type can't be booked", 'Помещение с частичным типом бронирования не может быть забронировано полностью.'],
	['Name already exists.', 'Офис с таким названием уже существует.'],
	['Not enough rights to edit office', 'Недостаточно прав для редактирования офиса.'],
	['Max office name length is 50.', 'Название офиса не может быть длиннее 50 символов.'],
	["City's name is too long.", 'Название города слишком длинное.'],
	['End time must be after start time', 'Время окончания работы офиса должно быть позже времени начала.'],
	['Failed to create an image', 'При добавлении фото произошла ошибка.'],

	//* - Wiki Service
	["Active article can't be in archived rubric", 'Неархивная статья не может быть внутри архивной рубрики.'],
	["Article content can't be empty", 'Содержимое статьи не может быть пустым.'],
	['The Content field is required.', 'Содержимое статьи не может быть пустым.'],
	['ParentId must not be empty', 'Необходимо указать родительскую рубрику.'],
	["Error converting value {null} to type 'System.Guid'. Path 'rubricId', line 1, position 16.", 'Необходимо указать родительскую рубрику.'],
	["Active sub-rubric can't be in archived rubric", 'Неархивная подрубрика не может быть внутри архивной рубрики.'],
	['This rubric does not exist or is not active', 'Эта рубрика не существует или находится в архиве'],
	['Parent id doesn`t exist', 'Такой родительской рубрики не существует'],
	['Article is not found', 'Статья не найдена.'],
	['Rubric is not found', 'Рубрика не найдена.'],

	//* Company Structure Service
	['Name must be unique.', 'Департамент с таким названием уже существует'],
	['Department with this Id already exists.', 'Департамент с таким названием уже существует'],
	["User's full name is too long", 'ФИО администратора слишком длинное.'],
	['Position with this name already exists', 'Должность с таким названием уже существует.'],
	['Incorrect assignment value', 'Некорректное значение должности.'],
	["User's description is too long", 'Описание для администратора слишком длинное.'],
	['Failed to create avatar', 'Произошла ошибка при попытке загрузить изображение.'],
	['Description is too long', 'Описание пользователя слишком длинное.'],
	["Incorrect user's assignment", 'Для пользователя выбрана некорректная должность.'],

	//* Feedback Service
	['Max file size is 20Mb', 'Максимальный размер загружаемого файла - 20 Мб.'],
	['Max total count of images is 5.', 'Можно добавить не больше пяти изображений.'],
	['File is not provided', 'Данный формат файла не поддерживается.'],
	['Failed to create file for feedback reply', 'Ошибка загрузки файла.'],
]);
